<template>
  <div class="body">
    <div class="content">
      <div class="title">
        <span>荣誉资质</span>
      </div>
      <br />
      <br />
      <div>
        <Edit></Edit>
        <!-- <MiniMCE style="width: 700px" v-model="content" /> -->
      </div>
      <br />
      <br />
      <el-button type="primary" @click="submit" size="medium">保存内容</el-button>
    </div>
  </div>
</template>

<script>
import Edit from '@/components/edit'
import http from '@/utils/cloud'
export default {
  components: {
    Edit
  },
  data() {
    return {
      project: localStorage.getItem('mall-project'),
      content: ''
    }
  },
  created() {
    this.getText()
  },
  methods: {
    getText() {
      http
        .POST('/jw-admin', {
          module: 'settings',
          operation: 'getSettings',
          project: JSON.parse(this.project)._id
        })
        .then(res => {
          if (res.honor) {
            this.content = res.honor
            if (tinymce.activeEditor.setContent) tinymce.activeEditor.setContent(res.honor)
          }
        })
    },
    submit() {
      this.content = tinymce.activeEditor.getContent()
      http
        .POST('/jw-admin', {
          module: 'settings',
          operation: 'updateSettings',
          honor: this.content,
          project: JSON.parse(this.project)._id
        })
        .then(res => {
          this.$message({
            message: '设置成功',
            type: 'success'
          })
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.body {
  width: 100%;
  height: 100%;
  padding: 15px;

  .content {
    height: 100%;
    background: #fff;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
    border-radius: 20px;
    .list {
      display: flex;
      align-items: center;
    }
    .title {
      font-size: 18px;
      font-weight: bold;
      padding-left: 12px;
      border-left: 6px solid #6775e1;
    }
  }
}
</style>
